import React from 'react';

const About = () => {
  return (
    <div className="flex flex-col items-center justify-center mt-10  py-4 px-4">
      <div className=" rounded-lg p-2  w-full">
        <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center">
          About <b className="text-blue-600">Facebook Video Downloader</b>
        </h1>
        <p className="text-gray-600 mb-4">
          Welcome to <b className="text-gray-800">Facebook Video Downloader</b>, where we share information related to Facebook Video Downloader. We're dedicated to providing you the very best information and knowledge on the above-mentioned topics.
        </p>
        <p className="text-gray-600 mb-4">
          We hope you found all of the information on <b className="text-gray-800">Facebook Video Downloader</b> helpful, as we love to share them with you.
        </p>
        <p className="text-gray-600 mb-6">
          If you require any more information or have any questions about our site, please feel free to contact us by email at{' '}
          <b className="text-blue-600 underline">admin@downloadfbvideo.com</b>.
        </p>
      </div>
    </div>
  );
};

export default About;
