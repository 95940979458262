import React from 'react';

const Contact = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen  py-10 px-4">
      <div className=" rounded-lg p-6  w-full">
        <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
          Contact us for <b className="text-blue-600">Facebook Video Downloader</b>
        </h2>
        <p className="text-gray-600 mb-4">
          Welcome to <b className="text-gray-800">Facebook Video Downloader</b>. We’re always eager to hear from you. Whether you want to share feedback, ask questions, or simply say hello, we’re here and ready to listen. At{' '}
          <a href="https://downloadfbvideo.com/" className="text-blue-600 underline">
            https://downloadfbvideo.com/
          </a>, your thoughts and suggestions help us grow, improve, and deliver a better experience.
        </p>
        <p className="text-gray-600 font-bold mb-2">You can contact us for any of the following:</p>
        <ul className="text-gray-600 list-disc list-inside mb-4">
          <li>
            <b>Website Feedback:</b> If you have any thoughts, opinions, or comments about our website, its design, or functionality, we would love to hear them. Your feedback helps us create a better user experience.
          </li>
          <li>
            <b>Content Queries:</b> Have a question or concern about our content? Whether you’re looking for clarification or need more details on a particular topic, feel free to ask. We’re here to provide the information you need.
          </li>
          <li>
            <b>Corrections or Updates:</b> If you come across any information in our posts that seems incorrect, outdated, or missing, let us know. We strive to provide accurate, up-to-date content, and your insights help us ensure we’re delivering the best.
          </li>
          <li>
            <b>Design Suggestions:</b> Do you have ideas on how we can improve our website’s appearance or usability? Whether it's changing the theme, colors, or layout, we’d appreciate your thoughts.
          </li>
          <li>
            <b>Improvement Suggestions:</b> We’re always looking for ways to improve <b className="text-gray-800">Facebook Video Downloader</b>. If you have any suggestions to enhance our site, whether in terms of content, tools, or features, don’t hesitate to share.
          </li>
          <li>
            <b>Technical Issues:</b> If you encounter any errors, bugs, or issues while using our site, please report them to us. We aim to provide a seamless experience, and your reports help us address problems quickly.
          </li>
        </ul>
        <p className="text-gray-600 mb-4">
          We welcome all your comments, suggestions, and concerns, as they help us make <b className="text-gray-800">Facebook Video Downloader</b> a better platform for everyone.
        </p>
        <p className="text-gray-600 font-bold mb-2">Don't hesitate to contact us:</p>
        <p className="text-gray-600">
          You can reach out to us via email at:{' '}
          <a href="mailto:admin@downloadfbvideo.com" className="text-blue-600 underline font-bold">
            admin@downloadfbvideo.com
          </a>. We’ll do our best to respond as soon as possible and address any concerns you may have. Thank you for being a part of our community and helping us grow!
        </p>
      </div>
    </div>
  );
};

export default Contact;
