import React from 'react'

const Content = () => {
  return (
    <section>
    <div class=" mx-auto p-6 bg-white shadow-lg rounded-lg">
        <h1 class="text-4xl font-bold text-blue-600 mb-4">Facebook Video Downloader</h1>
        <p class="text-gray-700 mb-4">
          Our Facebook Video Downloader makes it easy to save your favorite videos directly to your device. This tool is simple, fast, and secure, ensuring a hassle-free experience. Follow these steps to download videos effortlessly:
        </p>
        <ul class=" pl-6 text-gray-700 mb-4">
          <li>Copy the URL of the Facebook video you want to download.</li>
          <li>Paste the video URL into the input field on this page.</li>
          <li>Click the "Download" button to start processing your video.</li>
          <li>Select your preferred video quality from the available options.</li>
          <li>Click "Download" again to save the video to your device.</li>
        </ul>
        <p class="text-gray-700">
          With this tool, you can keep your favorite videos for offline viewing or sharing. No software installation is required, and it's completely free to use!
        </p>
      </div>
      
  </section>
  )
}

export default Content